


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getIpfsPath } from '../utils/utilities'
import { MissionMode, Rewards, TrooperzMetadata } from '@/type';
import RewardsIcon from '@/components/RewardsIcon.vue';
@Component({
    components: {
        RewardsIcon
    }
})
export default class BtnTraining extends Vue {
    @Prop()
    src!: string

    @Prop()
    mode!: MissionMode

    @Prop()
    rewards!: Rewards

    @Prop()
    cost!: Rewards

    emitClickEvent () {
        this.$emit('clickEvent')
    }

    get displayRewards () {
        return this.rewards ? this.rewards.amount : ''
    }

    get displayCost () {
        return this.cost ? this.cost.amount : ''
    }
}
