


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Attributes, TrooperzMetadata } from '@/type';
import _ from 'lodash'
@Component
export default class AttributesTable extends Vue {
    @Prop()
    attributes!: Attributes[]

    attrTable: Attributes[] = []

    doNotDisplay = ['tags', 'glitch']

    search = ''

    sortTableBy (event: any, name: string) {
        this.attrTable = this.$vs.sortData(event,this.attrTable, name)
    }

    @Watch("attributes", {immediate: true})
    onChangeAttributes() {
        this.attrTable = _.cloneDeep(this.attributes).filter(item => !this.doNotDisplay.includes(item.name)).map(item => {
            return {
                name: typeof item.name === 'string' ? item.name.toLowerCase() : item.name,
                value: typeof item.value === 'string' ? item.value.toLowerCase() : item.value
            }
        })
    }
}
