














































































































































import WalletMixin from '@/mixins/WalletMixin';
import HasRank from '@/mixins/HasRank';
import HasXp from '@/mixins/HasXp';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getIpfsPath, detectMobile, getExlorerUrl } from '../utils/utilities'
import { mixins } from 'vue-class-component';
import { Attributes, BigNumber, TrooperzAttributes, TrooperzMetadata, TrooperzApiResult, CrowdsaleContractStorage, MissionMode } from '@/type';
import { subAddress } from '@/utils/utilities';
import OperationLoadingDialog from '@/components/OperationLoadingDialog.vue';
import { getTrooperzById } from '../apiCaller/trooperzApiCaller'
import AttributesTable from '@/components/AttributesTable.vue';
import StatsBarFull from '@/components/StatsBarFull.vue';
import TrainingRoom from '@/components/TrainingRoom.vue';
import FamilyImg from '@/components/Common/FamilyImg.vue';
import { addressIsOwner, getContractStorage, getGetInBigMap } from '@/apiCaller/tzktApi';
import axios from 'axios';
import { getEnergyInfoBase, getEnergyBase } from '@/utils/rewardsHelper';
import BuyBtn from '@/components/BuyBtn.vue'

@Component({
    components: {
        OperationLoadingDialog,
        AttributesTable,
        StatsBarFull,
        TrainingRoom,
        HasRank,
        FamilyImg,
        BuyBtn
    }
})
export default class TrooperzFull extends mixins(WalletMixin, HasRank, HasXp) {
    @Prop()
    tokenId!: number

    @Prop()
    loading!: boolean

    @Prop()
    loadingMeta!: boolean

    @Prop()
    loadingStats!: boolean

    @Prop()
    trooperzMetadata!: TrooperzMetadata | null

    @Prop()
    trooperzAttributes!: TrooperzAttributes | null

    @Prop()
    xp!: BigNumber

    @Prop()
    maxXp!: BigNumber

    @Prop()
    energy!: BigNumber

    @Prop()
    maxEnergy!: BigNumber

    activeDialog = false

    loadingOpe = false

    success = false

    opeHash?: string | null = null

    toReveal: number[] = []

    toRevealLoaded = false

    loadingFull = null

    loadingIpfs = null

    refImgTrooperz = 'img-trooperz'

    currentMode: MissionMode | null = null
    energyCost: number | null = null
    rewards: number | null = null

    textRevealAlreadyAsked = `
    Your reveal has been already asked and your Trooperz will be soon revealed... 
    `

    textInfoRevealRevealNotStarted = `
        Reveal will start the Friday, May 20th From 2pm (UTC+2). Enjoy Trooperz!
    `

    textInfoReveal = `
    Your Trooperz is not revealed. Once you ask for reveal, your Trooperz will be revealed quickly. 
    `

    linkTrooperzPhone = 'https://trooperz.mypinata.cloud/ipfs/QmbqJbGrE8TwbLfaTtAj5SqKt8cs6XcMbjCvh5NEqdH3gY'

    maxStats = 10

    maxLvl = 50

    apiResult: TrooperzApiResult | null = null

    isRevealed = false

    apiResultMetadata: TrooperzMetadata | null = null

    isCurrentUserOwner = false

    async mounted () {
        this.initLoading()
        await this.loadToReveal()
        await this.checkIsCurrentUserOwner()

        if (this.isRevealed) {
            await this.callTrooperzApi()
        }
    }

    get attributes () {
         if (this.isRevealed) {
            // here, we check in case  the tzkt api is not up to date
            if (this.nameIsToReveal) {
                return this.apiResult?.attributes
            }
        }
        return this.trooperzMetadata?.attributes
    }

    get metadata (): TrooperzMetadata | null {
        if (this.apiResultMetadata) {
            return this.apiResultMetadata
        }
        return this.trooperzMetadata
    }

    get nameIsToReveal () {
        return this.trooperzMetadata?.name === 'Trooperz To Reveal'
    }

    get boostTrooperzPath () {
        return { name: 'consumables', query: { tokenId: this.tokenId } }
    }

    async getMetadataWithApiResult () {
        if (!this.apiResult) {
            return
        }
        let tokenId = this.tokenId
        const trpResult = this.apiResult
        let ipfsHash = trpResult.ipfs_hash
        if (!trpResult.ipfs_hash || this.toReveal.includes(tokenId)) {
            ipfsHash = 'QmamqDxvbpbPdhQE4dtv6CYC4HkyhP2Qmy5sZKqZj9U9xT'
        }
        const path = this.getIpfsPath('ipfs://' + ipfsHash)
        const data = await axios.get(path)
        let metadata = data.data
        metadata.token_id = tokenId
        return metadata
    }


    async callTrooperzApi () {
        const data = await getTrooperzById(this.tokenId)

        if (data.length) {
            this.apiResult = data[0]
        } else {
            console.warn(`Can not find Trooperz with id ${this.tokenId} in trooperz api.`)
        }
    }

    async checkIsCurrentUserOwner () {
        //this.isCurrentUserOwner = true
        //return
        let currentAccount = await this.$wallet.client.getActiveAccount()
        if (!currentAccount) {
            this.isCurrentUserOwner = false
        } else {
            const config = this.$config
            if (!config) {
                throw Error('Can not find config!')
            }
            const result = await addressIsOwner(config.networkTzkt, config.tokenContractAddress, currentAccount.address, this.tokenId)
            this.isCurrentUserOwner = !!result
        }
    }

    initLoading () {
        this.loadingFull = this.$vs.loading({
            text: 'Get contract data...',
            type: 'circles'
        })
    }

    async loadToReveal () {
        if (this.$config) {
            this.toRevealLoaded = false
            const result = await getGetInBigMap<boolean>(this.$config.networkTzkt, this.$config.tokenContractAddress, 'revealed', this.tokenId)
            this.isRevealed = result.value
            const storage = await getContractStorage<CrowdsaleContractStorage>(this.$config.networkTzkt, this.$config.marketplaceContractAddress)
            this.toReveal = storage.to_reveal
            this.toRevealLoaded = true
        }
    }

    startLoadingIpfs () {
        this.loadingIpfs = this.$vs.loading({
            target: this.$refs[this.refImgTrooperz],
            text: 'Get ipfs data...'
        })
    }

    stopLoadingIpfs () {
        if (this.loadingIpfs) {
            (this.loadingIpfs as any).close()
        }
    }

    manageErrorIpfs () {
        let img:(HTMLElement & {src: string}) | null = document.getElementById('trooperz-img-id') as (HTMLElement & {src: string}) | null
        if (img) {
            (this.loadingIpfs as any).changeText('Try reloading ipfs...')
            let source = img.src
            img.src = source
        }
    }

    getIpfsPath (ipfs: string) {
        return getIpfsPath(ipfs)
    }

    getFamily (attribute: Array<Attributes>) {
        let family = attribute.find(item => item.name === 'family')
        return family && typeof family.value === 'string' ? family.value : null
    }

    subAddress (str: string) {
        return subAddress(str.replace('ipfs://', ''))
    }

    getExplorerUrl () {
        if (this.$config) {
            return getExlorerUrl(this.$config?.networkTzkt, this.$config.tokenContractAddress, this.tokenId)
        }
        return ''
    }

    emitRefreshTrooperz () {
        this.initLoading()
        this.$emit('refresh-trooperz')
    }

    async callAskReveal () {
        if (!this.tokenId) {
            console.warn('No token id')
            return
        }
        let currentAddress = null
        const activeAccount = await this.$wallet.client.getActiveAccount();
        const config = this.$config
        if (!config) {
            throw Error('No config !')
        }
        if (!activeAccount) {
            let permissions = await this.requestPermissions()
            currentAddress = permissions?.address
        } else {
            currentAddress = activeAccount.address
        }
        
        if (currentAddress) {
            let contract = await this.$tezos.wallet.at(config.marketplaceContractAddress)

            let op = await contract.methods.ask_reveal(this.tokenId).send()
            this.opeHash = op.opHash
            this.activeDialog = true
            this.loadingOpe = true

            let hash = await op.confirmation(1)

            this.loadingOpe = false
            this.success = true
        } else {
            console.warn('Current address not find')
        }
    }

    getEnergy () {
        if ((this.rewards || this.energyCost ) && this.currentMode) {
            return getEnergyBase(this.energy, this.rewards, this.energyCost, this.currentMode, this.maxEnergy)
        }
        return this.energy ? this.energy : 0
    }

    getEnergyInfo () {
        if (this.rewards && this.energyCost && this.currentMode) {
            return getEnergyInfoBase(this.energy, this.rewards, this.energyCost, this.currentMode)
        }
        return null
    }

    get revealAlreadyAsked () {
        return (this.toReveal.includes(this.tokenId) || this.toReveal.includes(Number(this.tokenId))) || this.success
    }

    get stats () {
        return this.trooperzAttributes && this.trooperzAttributes.mutable_attributes ? this.trooperzAttributes.mutable_attributes : null
    }

    get rank () {
        return this.apiResult?.rank ? this.apiResult?.rank : null
    }

    get isTrainingRoomEnabled () {
        return this.$config?.hasTrainingRoom
    }

    

    get energyPoint () {
        if (!this.currentMode) {
            return this.energy
        }
        if (this.currentMode === 'rest') {
            return this.energy
        } else if (['mission', 'train'].includes(this.currentMode) && this.energyCost) {
            return this.energy
        }
        return this.energy
    }

    get energyPointDouble () {
        if (!this.currentMode) {
            return null
        }
        if (this.currentMode === 'rest') {
            return this.rewards
        } else if (['mission', 'train'].includes(this.currentMode) && this.energyCost) {
            return this.energy - this.energyCost
        }
        return null
    }

    getVideoTrooperzToReveal (ipfs: string) {
        if (detectMobile()) {
            return this.linkTrooperzPhone
        }
        return this.getIpfsPath(ipfs)
    }



    @Watch('loading')
    onChangeLoading () {
        if (!this.loading) {
            (this.loadingFull as any).close()
            this.$nextTick(() => {
                if (this.isRevealed) {
                    console.warn('Revealed img')
                    let trImg: any = document.getElementById('trooperz-img-id')
                    if (trImg && trImg.complete) {
                        this.stopLoadingIpfs()
                    } else {
                        this.startLoadingIpfs()
                        if (trImg) {
                            trImg.addEventListener('load', this.stopLoadingIpfs.bind(this))
                            trImg.addEventListener('error', this.manageErrorIpfs.bind(this))
                        }
                    }
                } else {
                    console.warn('Not revealed img')
                }

            })
        }
    }

    @Watch('trooperzMetadata', {deep: true})
    async onChangeTrooperzMetadata () {
        if (this.trooperzMetadata) {
            if (this.nameIsToReveal) {
                this.apiResultMetadata = await this.getMetadataWithApiResult()
            }
        }
    }

    @Watch('loadingMeta')
    onChangeLoadingMeta () {
        if (this.loadingMeta) {
            (this.loadingFull as any).changeText('Loading metadata...')
        }
    }

    @Watch('loadingStats')
    onChangeLoadingStats () {
        if (this.loadingStats) {
            (this.loadingFull as any).changeText('Loading Trooperz stats...')
        }
    }


    @Watch('$store.getters.getAddress', {deep: true})
    onChangeAddress () {
        this.checkIsCurrentUserOwner()
    }


    beforeDestroy () {
        if (this.loadingFull) {
            console.warn('destroy');
            (this.loadingFull as any).close()
        }
    }

}
