




















import WalletMixin from '@/mixins/WalletMixin';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getTrooperzStats } from '../contractCaller/contractCaller'
import {getContractStorage, getGetInBigMap, getTokenMetadata} from '../apiCaller/tzktApi'
import { mixins } from 'vue-class-component';
import { TrooperzMetadata, MutableAttributes, TrooperzAttributes, TrooperzStatsData, TokenContractStorage } from '@/type';
import TrooperzFull from '@/components/TrooperzFull.vue';
import { getTrooperzById } from '@/apiCaller/trooperzApiCaller';
import { getIpfsPath } from '@/utils/utilities';
import axios from 'axios';
@Component({
  components: {
      TrooperzFull
  },
})
export default class Trooperz extends Vue {
    @Prop()
    tokenId!: number
    loading = false
    loadingStats = false
    loadingMeta = false
    trooperzMetadata: TrooperzMetadata | null = null

    currentAddress: string | null = null
    trooperzAttributes: null | TrooperzAttributes = null
    xp: null | number = null
    energy: null | number = null
    maxXp: null | number = null
    maxEnergy: null | number = null

     async mounted () {
        await this.getAddress()
        await this.getTrooperz()
    }

    async getAddress () {
        const activeAccount = await this.$wallet.client.getActiveAccount()
        if (activeAccount?.address) {
            let address = activeAccount.address
            this.currentAddress = address
        } else {
            this.currentAddress = null
        }
    }

    async getTrooperz () {
        if (this.$config) {
            this.loading = true
            this.trooperzMetadata = null
            this.loadingMeta = true
            let metadata = await this.getTrooperzMetadata(this.tokenId)
            this.loadingMeta = false
            this.loadingStats = true
            let stats = await this.getTrooperzStats(this.tokenId)

            this.loadingStats = false
            if (metadata) {
                this.trooperzMetadata = metadata
            } else {
                const apiResult = await getTrooperzById(this.tokenId)
                let tokenId = this.tokenId
                const path = getIpfsPath('ipfs://' + apiResult[0].ipfs_hash)
                const data = await axios.get(path)
                let metadata = data.data
                metadata.token_id = tokenId
                this.trooperzMetadata = metadata
            }
            if (stats) {
                this.trooperzAttributes = stats.trooperzAttributes
                this.xp = stats.xp
                this.energy = stats.energy
                this.maxXp = stats.maxXp
                this.maxEnergy = stats.maxEnergy
            }
            this.loading = false
        }
    }

    async getTrooperzStats (trooperzId: number): Promise<TrooperzStatsData | null> {
        const attributes = await this.getTrooperzAttributes(trooperzId)
        const xp = await this.getTrooperzXp(trooperzId)
        const energy = await this.getTrooperzEnergy(trooperzId)
        const storage = await this.getTrooperzStorage()

        if (storage && xp && energy && attributes) {
            return {
                trooperzAttributes: attributes,
                xp: Number(xp),
                energy: Number(energy),
                maxXp: Number(storage.max_xp),
                maxEnergy: Number(storage.max_energy)
            }
        }
        return null
    }

    async getTrooperzAttributes(trooperzId: number) {
        if (this.$config) {
            const result = await getGetInBigMap<TrooperzAttributes>(this.$config.networkTzkt, this.$config.tokenContractAddress, 'trooperz_attributes', trooperzId)
            if (result) {
                return result.value
            }
        }
    }

    async getTrooperzXp(trooperzId: number) {
        if (this.$config) {
            const result = await getGetInBigMap<string>(this.$config.networkTzkt, this.$config.tokenContractAddress, 'xp_map', trooperzId)
            if (result) {
                return result.value
            }
        }
    }

    async getTrooperzEnergy(trooperzId: number) {
        if (this.$config) {
            const result = await getGetInBigMap<string>(this.$config.networkTzkt, this.$config.tokenContractAddress, 'energy_map', trooperzId)
            if (result) {
                return result.value
            }
        }
    }

    async getTrooperzStorage() {
        if (this.$config) {
            const result = await getContractStorage<TokenContractStorage>(this.$config.networkTzkt, this.$config.tokenContractAddress)
            if (result) {
                return result
            }
        }
        return null
    }

    async getTrooperzMetadata (trooperzId: number): Promise<TrooperzMetadata|null> {
        if (this.$config) {
            const response = await getTokenMetadata(this.$config.networkTzkt, this.$config.tokenContractAddress, trooperzId)
            if (response?.length) {
                return response[0].metadata
            }
        }
        return null
    }
    
}
