





































































































































































































import { BigMapKeyTzkt, BigMapTzKt, BigNumber, JsonMission, MissionConfig, MissionData, MissionMode, Rewards, RewardsInfo, RewardsType, TrainingConfig, TrooperzAttributes, TrooperzInfo, TrooperzInfoMission, WithKey } from '@/type';
import { Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import { setStrooperzMode, harvestTrooperz, getContractStorage, removeTrooperz, setStrooperzModeUpdateContract } from '../contractCaller/contractCaller'
import WalletMixin from '../mixins/WalletMixin';
import { mixins } from 'vue-class-component';
import ChooseMissionDialog from '@/components/ChooseMissionDialog.vue';
import OperationLoadingDialog from '@/components/OperationLoadingDialog.vue';
import BtnTraining from '@/components/BtnTraining.vue';
import RewardsIcon from '@/components/RewardsIcon.vue';
import { getBigMapsOfContract, getBigMap, getBlockCount, getGetInBigMap, getContractStorage as getStorageTzkt } from '../apiCaller/tzktApi'
import { getMissionData } from '../apiCaller/getMissionInfo'
import { addKeyToBigMap, calcBonusTcoin, convertValueFromContract, countdown, getLvlWithXp } from '@/utils/utilities';
import { calcTrooperzRewards } from '@/utils/rewardsCalculator';
import { convertByBlockToByHours, getDateWithRemainingBlock, getEndBlockMission, getEndMissionDate, getInforRewardsTcoin, getJsonMission, getRoiByHours, isMissionEndedDate } from '@/utils/missionHelper';
import { Config } from 'config/config_type';

@Component({
    components: {
        ChooseMissionDialog,
        BtnTraining,
        RewardsIcon,
        OperationLoadingDialog
    }
})
export default class TrainingRoom extends mixins(WalletMixin) {
    @Prop()
    trooperzId!: number

    @Prop()
    energy!: number

    @Prop()
    xp!: number

    @Prop()
    maxEnergy!: number

    @Prop()
    maxXp!: number

    @Prop()
    trooperzAttributes!: TrooperzAttributes | null

    @PropSync("currentModeProps")
    currentMode!: MissionMode | null

    @PropSync("energyCostProps")
    energyCost!: null | number

    @PropSync("rewardsProps")
    rewards!: null | number

    activeDialogHarvestRemove = false
    currentAskHarvestRemove: 'harvest' | 'remove' | null = null

    opeHash?: string | null = null
    activeDialog = false
    loading = false
    success = false

    dialogChooseMission = false
    missionList: Array<WithKey<MissionConfig>> = []
    missionData: Array<WithKey<MissionData>> = []

    // filled only is trooperz is on a old contract
    newMissionList: Array<WithKey<MissionConfig>> = []
    newMissionData: Array<WithKey<MissionData>> = []

    trooperzInfo: TrooperzInfo | null = null
    
    missionCurrentMode: MissionConfig | null = null
    isCurrentMissionEnded = false
    jsonMissionCurrentMode: JsonMission | null = null
    trainingConfig: null | TrainingConfig = null
    blockedTrooperz: null | string[] = []
    
    isOnMaxRewards = false

    currentBlock: number | null = null

    remainingBlock: number | null = null
    dateEndRewards: Date | null = null
    countdownEndRewards: string | null = null
    countdownEndMission: string | null = null
    
    isInPreviousContract = false

    intervalBlockCount: number | null = null

    textInfoChangeMode = `
    The training-room smart-contract has been updated. This trooperz is training with the old contract. 
    During your next mode change, 
    your trooperz will automatically be removed from the old contract 
    to be put in the new one. 

    The old contract will be deactivated the 7 July, and all non claimed rewards will be lose. 
    
    So, try to change mode before the next cycle :)
    `

    async checkIsInPreviousContract () {
        const prevContract = this.prevContract()
        if (prevContract && this.$config) {
            let prevInfo = await getGetInBigMap<TrooperzInfo>(this.$config.networkTzkt, prevContract, 'ledger', Number(this.trooperzId))

            if (prevInfo && prevInfo.active) {
                // if trooperz is blocked and trooperz is in current trooperz, that means its solved
                if (this.isCurrentTrooperzBlocked() && await this.checkIsInCurrentContract()) {
                    this.isInPreviousContract = false
                    return
                }
                // otherwise, trooperz is in previous contract
                this.isInPreviousContract = true
            }
        }
    }

    async checkIsInCurrentContract () {
        const config = this.getConfig()
        let info = await getGetInBigMap<TrooperzInfo>(config.networkTzkt, config.trainingRoomAddress, 'ledger', Number(this.trooperzId))
        return info && info.active
    }

    prevContract () {
        const config = this.$config
        if (!config) {
            throw Error('No config!')
        }
        if (!config.oldContracts.length) {
            return null
        }
        return config.oldContracts[0]
    }

    getConfig (): Config {
        if (!this.$config) {
            throw Error('No config!')
        }
        return this.$config
    }

    getTrainingRoomAddress () {
        const config = this.getConfig()
        if (this.isInPreviousContract) {
            return this.prevContract() as string
        }
        return config?.trainingRoomAddress
    }

    goToMission (missionKey: string) {
        this.setTrooperzMode('mission', Number(missionKey))
    }

    async getBlockedTrooperz () {
        const config = this.getConfig()
        const result = await getStorageTzkt<string[]>(config.networkTzkt, config.trainingRoomAddress, ['blocked_trooperz'])
        this.blockedTrooperz = result
    }

    isCurrentTrooperzBlocked () {
        return this.blockedTrooperz && this.blockedTrooperz.includes(String(this.trooperzId))
    }

    async getTrooperzData () {
        let config = this.getConfig()
        // let storage = await getContractStorage(this.$tezos, this.getTrainingRoomAddress())
        let storage = await getStorageTzkt<any>(config.networkTzkt, this.getTrainingRoomAddress())
        this.trainingConfig = storage.config
        const ledgerResult = await getGetInBigMap<TrooperzInfo>(config.networkTzkt, this.getTrainingRoomAddress(), 'ledger', Number(this.trooperzId))

        if (!ledgerResult.active) {
            console.warn('Training map not active')
            return
        }
        this.trooperzInfo = ledgerResult.value
    }

    async getInfoCurrentMission () {
        if (this.trooperzInfo) {
            const config = this.getConfig()
            this.currentMode = (Object.keys(this.trooperzInfo)[0] as MissionMode)

            this.$nextTick(async () => {
                if (this.currentMode === 'mission') {
                    const missionId = this.currentMissionId
                    let mission = await getGetInBigMap<MissionConfig>(config.networkTzkt, this.getTrainingRoomAddress(), 'mission_config', Number(missionId))

                    if (mission) {
                        this.$set(this,'missionCurrentMode', mission.value)

                        if (this.missionCurrentMode) {
                            this.missionCurrentMode.end_mission = this.getEndMissionDate(this.missionCurrentMode)
                        }
                        this.jsonMissionCurrentMode = await getJsonMission(mission.value.ipfs_link)
                    }
                }
            })

        } else {
            console.warn('Can not find Trooperz train info')
        }
    }

    isUnique () {
        const config = this.$config
        return config && config.uniqueTrooperz.includes(Number(this.trooperzId))
    }

    calcRewards (mode: MissionMode, startBlock: BigNumber, currentBlock: number, 
                trainingConfig: TrainingConfig, energy: number, xp: number,
                maxEnergy: number, maxXp: number): RewardsInfo | null     {

        let config = this.getConfig()
        return calcTrooperzRewards(mode, 
                                   startBlock, 
                                   currentBlock, 
                                   trainingConfig, 
                                   energy, 
                                   xp, 
                                   maxEnergy, 
                                   maxXp, 
                                   this.currentMission, 
                                   !!this.isUnique(), 
                                   config.nbDigitTcoin)
    }

    getStartBlock (trooperzInfo: TrooperzInfo, mode: MissionMode) {
        if (['rest', 'train'].includes(mode)) {
            return trooperzInfo[mode]
        }
        // mission
        return trooperzInfo[mode].start_block
    }

    getInfoRewards() {
        if (!this.currentMode) {
            return 'Click on "rest", "train" or "mission" to select a mode.'
        }
        if (this.currentMode === 'rest') {
            return 'Rest mode'
        } else if (this.currentMode === 'train') {
            return 'Train mode'
        }
        let config = this.getConfig()
        return getInforRewardsTcoin(Number(this.missionCurrentMode?.tcoin_by_block), this.xp, config.nbDigitTcoin, this.isUnique())
    }

    async openChooseMission () {
        this.dialogChooseMission = true
    }

    async getMissionDataAndMissionList () {
        const res = await this.getMissionData(this.getTrainingRoomAddress())
        this.missionList = res.missionList
        this.missionData = res.missionData
        if (this.isInPreviousContract) {
            const config = this.getConfig()
            const res = await this.getMissionData(config?.trainingRoomAddress)
            this.newMissionList = res.missionList
            this.newMissionData = res.missionData
        }
    }

    async getMissionData (trainingRoomAddress: string): Promise<{missionData: Array<WithKey<MissionData>>, missionList: Array<WithKey<MissionConfig>>}> {
        const config = this.getConfig()
        return getMissionData(config, this.currentBlock, trainingRoomAddress)
    }

    getEndMissionDate (mission: MissionConfig) {
        const config = this.getConfig()
        return getEndMissionDate(config.timeBlock, this.currentBlock, mission)
    }

    getDateWithRemainingBlock (remainingBlock: number) {
        const config = this.getConfig()
        return getDateWithRemainingBlock(config.timeBlock, remainingBlock)
    }


    addKeyToBigMap<T> (bigMap: BigMapKeyTzkt<T>): WithKey<T> {
        return addKeyToBigMap(bigMap)
    }

    isActiveMode (mode: MissionMode) {
        return this.currentMode === mode
    }

    addActiveIfActive (mode: MissionMode) {
        return this.isActiveMode(mode) ? '_ACTIVE' : ''
    }

    currentModeIs (mode: MissionMode) {
        return this.currentMode === mode
    }

    getCurrentRewards (): RewardsType | null {
        if (this.currentModeIs('rest')) {
            return 'energy'
        } else if (this.currentModeIs('train')) {
            return 'xp'
        } else if (this.currentModeIs('mission')) {
            return 'tcoin'
        }
        console.warn('Can not find rewards type str')
        return null
    }

    getCurrentRewardsTypeStr () {
        if (this.currentModeIs('rest')) {
            return 'Energy'
        } else if (this.currentModeIs('train')) {
            return 'Xp'
        } else if (this.currentModeIs('mission')) {
            return 'TCoin'
        }
        console.warn('Can not find rewards type str')
    }

    async harvest () {
        this.harvestOrRemoveTrooperz('harvest')
    }

    async remove () {
        this.harvestOrRemoveTrooperz('remove')
    }

    async harvestOrRemoveTrooperz (entrypoint: 'harvest' | 'remove') {
        let currentAddress = await this.getCurrentAccountAndRequestPermissionsIfNot()
        let config = this.getConfig()
        if (currentAddress) {
            this.success = false
            let op = null
            let hash = null
            try {
                if (entrypoint === 'harvest') {
                    op = await harvestTrooperz(this.$tezos.wallet, this.trooperzId, config.trainingRoomAddress)
                } else if (entrypoint === 'remove') {
                    op = await removeTrooperz(this.$tezos.wallet, this.trooperzId, this.getTrainingRoomAddress())
                } else {
                    throw Error('Unknow entrypoint type : ' + entrypoint)
                }
            } catch(er) {
                console.warn(er)
                console.warn('Error during call')
                return
            }

            console.warn(op)
            this.opeHash = op.opHash
            this.activeDialog = true
            this.loading = true
            try {
                hash = await op.confirmation(1)
            } catch(er) {
                console.warn('Error during confirmation. retry confirm...')
                hash = await op.confirmation(1)
            }
            console.warn(hash)

            this.loading = false
            this.success = true
        }
    }

    async setTrooperzMode (mode: MissionMode, missionId: null | number = null) {
        let currentAddress = await this.getCurrentAccountAndRequestPermissionsIfNot()
        let config = this.getConfig()
        if (currentAddress) {
            this.success = false
            let op = null
            let hash = null
            let prevContract = this.prevContract()
            if (this.isInPreviousContract && prevContract && !this.isCurrentTrooperzBlocked()) {
                try {
                    op = await setStrooperzModeUpdateContract(this.$tezos.wallet, this.trooperzId, mode, config.trainingRoomAddress, prevContract, missionId)
                } catch(er) {
                    console.warn(er)
                    console.warn('Error during call, Update contract')
                    return
                }
            } else {
                try {
                    op = await setStrooperzMode(this.$tezos.wallet, this.trooperzId, mode, config.trainingRoomAddress, missionId)
                } catch(er) {
                    console.warn(er)
                    console.warn('Error during call')
                    return
                }
            }


            this.opeHash = op.opHash
            this.activeDialog = true
            this.loading = true
            try {
                hash = await op.confirmation(1)
            } catch(er) {
                console.warn('Error during confirmation. retry confirm...')
                hash = await op.confirmation(1)
            }

            this.loading = false
            this.success = true
            if (!this.activeDialog) {
                this.emitRefreshTrooperz()
            }
        }
    }

    emitRefreshTrooperz () {
        this.$emit('refresh-trooperz')
    }

    async refreshData () {
        await this.getTrooperzData()
        this.refreshRewards()
    }

    async refreshRewards () {
        console.warn('refresh rewards')
        let endblock = this.currentBlock

        if (this.trooperzInfo && this.currentMode && this.trainingConfig && endblock) {
            let startBlock = this.getStartBlock(this.trooperzInfo,this.currentMode)
            if (startBlock) {
                let result = this.calcRewards(this.currentMode, startBlock, endblock, 
                                                this.trainingConfig, this.energy, this.xp,
                                                this.maxEnergy, this.maxXp)
                if (result) {
                    this.rewards = result.rewards
                    this.energyCost = result.energyCost
                    this.isOnMaxRewards = result.isOnMaxRewards
                    this.remainingBlock = result.remainingBlock
                } else {
                    console.warn('Can not find result')
                }
            } else {
                console.warn('Can not calc rewards')
            }
        }
    }

    getCountdownRemainingBlocks () {
        if (this.remainingBlock) {
            const config = this.getConfig()
            let remSeconds = this.remainingBlock * config.timeBlock
            let remMilliseconds = remSeconds * 1000
            return countdown(remMilliseconds)
        }
        return null
    }

    get currentMission (): WithKey<MissionConfig> | null {
        let mission = null
        if (this.currentMode === 'mission') {
            let missionId = (this.trooperzInfo as TrooperzInfoMission)[this.currentMode].mission_id.toString()
            let res = this.missionList.find(item => item.key === missionId)
            if (res) {
                mission = res
            }
        }
        return mission
    }

    get textRemainingBlock () {
        if (!this.remainingBlock || !this.currentMode) {
            return ''
        }
        if (['mission', 'train'].includes(this.currentMode)) {
            return `${this.countdownEndRewards} left before the Trooperz run out of energy`
        } else if (this.currentMode == 'rest') {
            return `${this.countdownEndRewards} minutes left before the Trooperz recovers all its energy`
        }
        return ''
    }

    get textEndMission () {
        return `${this.countdownEndMission} left before the mission end.`
    }

    askHarvest () {
        this.currentAskHarvestRemove = 'harvest'
        this.activeDialogHarvestRemove = true
    }

    askRemove () {
        this.currentAskHarvestRemove = 'remove'
        this.activeDialogHarvestRemove = true
    }

    closeHarvestRemove () {
        this.activeDialogHarvestRemove = false
        this.currentAskHarvestRemove = null
    }

    doHarvestRemove () {
        if (this.currentAskHarvestRemove === 'remove') {
            this.remove()
            this.closeHarvestRemove()
        } else if (this.currentAskHarvestRemove === 'harvest') {
            this.harvest()
            this.closeHarvestRemove()
        } else {
            console.warn('Not implemented. Harvest/Remove')
        }
    }

    get missionListCurrentContract () {
        if (this.isInPreviousContract) {
            return this.newMissionList
        }
        return this.missionList
    }

    get missionDataCurrentContract () {
        if (this.isInPreviousContract) {
            return this.newMissionData
        }
        return this.missionData
    }

    get harvestRemoveDialogText () {
        return `
        Switching modes automatically makes a harvest. 
        You don't need harvest or remove between each mode. Do you really want to ${this.currentAskHarvestRemove} your Trooperz?
    `
    }

    get rewardsEnergy(): Rewards | null {
        if (!this.trainingConfig || !this.$config) {
            return null
        }
        return {
            type: 'energy',
            amount: convertByBlockToByHours(this.trainingConfig.energy_by_block, this.$config.timeBlock)
        }
    }

    get rewardsXp(): Rewards | null {
        if (!this.trainingConfig || !this.$config) {
            return null
        }
        return {
            type: 'xp',
            amount: convertByBlockToByHours(this.trainingConfig.xp_by_block, this.$config.timeBlock)
        }
    }

    get costTrain(): Rewards | null {
        if (!this.trainingConfig || !this.$config) {
            return null
        }
        return {
            type: 'energy',
            amount: convertByBlockToByHours(this.trainingConfig.train_energy_cost_by_block, this.$config.timeBlock)
        }
    }


    get costMission(): Rewards | null {
        if (!this.trainingConfig || !this.$config) {
            return null
        }
        return {
            type: 'energy',
            amount: convertByBlockToByHours(this.trainingConfig.mission_energy_cost_by_block, this.$config.timeBlock)
        }
    }


    get textMaxRewards () {
        if (this.currentMode === 'rest') {
            return 'You are full of energy! Go on a mission or go train!'
        }
        return `You have already used up all your energy, and you can't earn any more rewards.
                            Go rest to regain your energy!`
    }

    get currModeText () {
        if (this.currentMode) {
            let _default = `Current Mode : ${this.currentMode}`
            

            if (this.currentMode === 'mission' && this.missionCurrentMode && this.$config && this.xp && this.costMission) {
                const info = this.jsonMissionCurrentMode?.title
                const infoRend = getRoiByHours(Number(this.missionCurrentMode.tcoin_by_block), 
                                               this.$config.timeBlock, 
                                               this.xp, 
                                               this.$config.nbDigitTcoin,
                                               this.isUnique())
                return `${_default} (${info}) | +${infoRend} $TCOIN / hours | -${this.costMission.amount} energy / hours`
            } else if (this.currentMode === 'rest' && this.rewardsEnergy) {
                return `${_default} | +${this.rewardsEnergy.amount} energy / hours`
            } else if (this.currentMode === 'train' && this.rewardsXp && this.costTrain) {
                return `${_default} | +${this.rewardsXp.amount} xp / hours | -${this.costTrain.amount} energy / hours`
            }
            return _default
        }
        return 'Select a mode!'
    }

    get currentMissionId () {
        if (this.currentMode === 'mission' && this.trooperzInfo && Object.keys(this.trooperzInfo).includes(this.currentMode)) {
            return Number((this.trooperzInfo as TrooperzInfoMission)[this.currentMode].mission_id)
        }
        return null
    }

    get textDisabledHarvest () {
        if (this.isCurrentMissionEnded) {
            return `
                Harvest is not allowed on a ended mission. 
                Change mode, and the harvest will trigger automatically. 
            `
        }
        if (this.isInPreviousContract) {
            return `
                Unable to harvest during contract transition. 
                Changes mode and the contract will be automatically updated and the rewards collected.
            `
        }
        return null
    }

    @Watch('missionCurrentMode.end_mission', {deep: true})
    onChangeEndMission () {
        if (this.missionCurrentMode?.end_mission) {
            this.isCurrentMissionEnded = isMissionEndedDate(this.missionCurrentMode)
        }
        // end date mission
        if (this.currentMode === 'mission' && this.missionCurrentMode?.end_mission) {
            let distance = this.missionCurrentMode.end_mission.getTime() - (new Date().getTime())
            this.countdownEndMission = countdown(distance)
            window.setInterval(() => {
                if (this.currentMode === 'mission' && this.countdownEndMission && this.missionCurrentMode?.end_mission) {
                    let distance = this.missionCurrentMode.end_mission.getTime() - (new Date().getTime())
                    this.countdownEndMission = countdown(distance)
                    this.isCurrentMissionEnded = isMissionEndedDate(this.missionCurrentMode)
                }
                return null
            }, 1000)
        }
    }

    @Watch('trooperzId', {immediate: true})
    async onChangeTrooperzId() {
        let config = this.getConfig()
        this.currentBlock = await getBlockCount(config.networkTzkt)
        await this.getBlockedTrooperz()
        await this.checkIsInPreviousContract()
        await this.getTrooperzData()
        await this.getMissionDataAndMissionList()   
        await this.getInfoCurrentMission()    
        this.refreshRewards()
        // on calcul la date de fin de rewards avec remaining block
        if (this.remainingBlock) {
            this.dateEndRewards = this.getDateWithRemainingBlock(this.remainingBlock)
            window.setInterval(() => {
                if (this.dateEndRewards) {
                    let distance = this.dateEndRewards.getTime() - (new Date().getTime())
                    this.countdownEndRewards = countdown(distance)
                }
                return null
            }, 1000)
        }

        this.setIntervalBlockCount(config)
    }

    setIntervalBlockCount (config: Config) {
        if (this.intervalBlockCount) {
            return
        }
        this.intervalBlockCount = window.setInterval(async () => {
            this.currentBlock = await getBlockCount(config.networkTzkt)
            this.refreshRewards()
        }, 20000)
    }

    @Watch('activeDialog', {immediate: false})
    onChangeActiveDialog () {
        // "on close" activeDialog and loading finished
        if (!this.activeDialog && !this.loading) {
            this.emitRefreshTrooperz()
        }

        if (this.intervalBlockCount) {
            clearInterval(this.intervalBlockCount)
        }
    }
}
