import { render, staticRenderFns } from "./AttributesTable.vue?vue&type=template&id=b38d8132&"
import script from "./AttributesTable.vue?vue&type=script&lang=ts&"
export * from "./AttributesTable.vue?vue&type=script&lang=ts&"
import style0 from "./AttributesTable.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports